import CheckOut from "./components/CheckOut";
import TeamMail from "./components/TeamMail";


function App() {
  return (
    <div className="App">
      <TeamMail ></TeamMail>
    </div>
  );
}

export default App;
