import React, { useState, useEffect } from 'react';
import './teammail.css'; // Import the CSS file
import { RiFileCopyLine, RiRefreshLine } from 'react-icons/ri';
import { TbArrowsExchange2 } from 'react-icons/tb';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import Htmlviewer from './Htmlviewer';
import { v4 as uuidv4 } from 'uuid';

const TempMailo = () => {
    const [tempEmail, setTempEmail] = useState('');
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emails, setEmails] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [counter, setCounter] = useState(15);
    const fixedEmail = 'ngtmail101@gmail.com';
    const [randomIdentifier, setRandomIdentifier] = useState('');
    useEffect(() => {
        // Fetch email data from the backend
        refreshEmail();
        fetch('https://ng.mail.airepro.in/emails')
            .then((response) => response.json())
            .then((data) => setEmails(data))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        // Update the counter every second
        const interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        // Clear the interval when the counter reaches 0
        if (counter === 0) {
            clearInterval(interval);
        }

        // Reset the counter to 15 and fetch emails
        if (counter === 0) {
            setCounter(15);
            //     fetch('http://localhost:4000/emails')
            //         .then((response) => response.json())
            //         .then((data) => setEmails(data))
            //         .catch((error) => console.error(error));
        }

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, [counter]);


    function copyEmail() {
        const input = document.getElementById('temp-email');
        const textToCopy = input.value;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000); // Hide the message after 2 seconds
            })
            .catch((error) => {
                console.error('Error copying text:', error);
                // Optionally, you can show an error message or perform any other action.
            });
    };
    const refreshEmail = () => {
        // Logic to generate a new temporary email address
        const randomText = generateRandomText(2, 3); // Generate a unique identifier using the uuid library
        const randomEmail = `ngtmail101+${randomText}@gmail.com`;
        setTempEmail(randomEmail);
    };
    const refreshCount = () => {
        setCounter(0);
    }
    function generateRandomText(minLength, maxLength) {
        const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
        let text = '';
        const possibleChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * possibleChars.length);
            text += possibleChars.charAt(randomIndex);
        }
        return text;
    }

    const googleLogin = () => {
        window.location.href = 'https://ng.mail.airepro.in/google';
    }
    return (
        <div className="tempmailo">
            <header className="p-4 bg-primary">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="header-title">ngMail</h1>
                        <button className="btn btn-login" onClick={googleLogin}>LOGIN</button>
                    </div>
                </div>
            </header>
            <div className="tempmailo-body">
                <div className="container">
                    <div className="tempmailo-email-container">
                        <h4 > Email Address:</h4>
                        <div className="input-group">
                            <input type="text" className="form-control" id="temp-email" value={tempEmail} readOnly />
                            <button className="btn round-btn" onClick={copyEmail}>{isCopied ? <TiTick className="icon" /> : <RiFileCopyLine className="icon" />}</button>
                        </div>
                    </div>
                    <div className="refresh-container">
                        <div className="count">Autorefresh in <span style={{ border: '2px solid blue', borderRadius: '50%', padding: '5px' }}>{counter}</span></div>
                        <button className="btn round-refresh" onClick={refreshCount}><HiOutlineRefresh className="icon" /><h4 style={{ fontSize: '16px' }}>Refresh</h4></button>
                        <button className="btn round-refresh" onClick={refreshEmail}><TbArrowsExchange2 className="icon" /><h4 style={{ fontSize: '16px' }}>Change</h4></button>
                    </div>

                    <section className="py-5">
                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="tempmailo-inbox-heading">Inbox</h2>
                                <ul className="list-group tempmailo-email-list" id="email-list">
                                    {/* Render email list items dynamically using the 'emails' state */}
                                    {emails.map((email, index) => (
                                        <li
                                            key={index}
                                            className={`list-group-item ${selectedEmail === index ? 'active' : ''}`}
                                            onClick={() => setSelectedEmail(index)}
                                        >
                                            {email.subject}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-8">
                                {selectedEmail !== null && (
                                    <div className="card tempmailo-email-card">
                                        <div className="card-header">
                                            <h5>{emails[selectedEmail].subject}</h5>
                                            <small className="text-white">Sender: {emails[selectedEmail].sender}</small>
                                        </div>
                                        <div className="card-body">
                                            <Htmlviewer html={emails[selectedEmail].body} />
                                            <div className="tempmailo-email-actions">
                                                {/* <a href="#" className="btn btn-primary">Reply</a>
                                                <a href="#" className="btn btn-primary">Forward</a> */}
                                                {emails[selectedEmail].attachments.length > 0 && (
                                                    <a href="#" className="btn btn-primary">Download Attachment</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* <footer className="bg-light py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="list-inline tempmailo-footer-links">
                                <li className="list-inline-item"><a href="#">Privacy Policy</a></li>
                                <li className="list-inline-item"><a href="#">Terms of Service</a></li>
                                <li className="list-inline-item"><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-end">
                            <select className="form-select" value={language} onChange={changeLanguage}>
                                <option value="en">English</option>
                                <option value="fr">French</option>
                                {/* Add more language options as needed */}
            {/* </select> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </footer>  */}
        </div>
    );
};

export default TempMailo;
